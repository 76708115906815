<template>
  <div class="flex min-h-full flex-1 flex-col justify-center mx-5 py-12 sm:px-6 lg:px-8">
    <NuxtLink
      to="/"
      class="sm:mx-auto sm:w-full sm:max-w-md"
    >
      <AssetIconEnvolop svg-class="mx-auto h-8 w-8" />
    </NuxtLink>

    <slot />
  </div>
</template>

<script setup>
useHead({
  viewport: 'width=device-width, initial-scale=1, maximum-scale=1',
  charset: 'utf-8',
  bodyAttrs: {
    class: 'h-full bg-gray-50 dark:bg-black'
  },
  htmlAttrs: {
    class: 'h-full'
  }
})
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
